import SocialProjectManager from '../../clients/abdaSocialProjectManager'
import { merge } from 'lodash'

const resourceName = 'classregistration'
const resourceClass = 'class'

const GetById = id => SocialProjectManager.get(`${resourceName}/${id}`)
const GetAll = () => SocialProjectManager.get(resourceName)
const GetByClass = classId => SocialProjectManager.get(`${resourceName}/${classId}/${resourceClass}`)
const GetByClassStudent = (classId, pagination) => SocialProjectManager.get(`${resourceName}/${classId}/students`, { 
  params: merge(classId, pagination) 
})

const Create = (classId, data) => SocialProjectManager.post(`${resourceName}/${classId}`, data)
const SaveMovement = (classId, data) => SocialProjectManager.post(`${resourceName}/movement/${classId}`, data)

const Update = data => SocialProjectManager.put(`${resourceName}/${data.studentId}`, data)

const Delete = id => SocialProjectManager.delete(`${resourceName}/${id}`)

const ClassRegistrationRepository = {
  GetById,
  GetAll,
  GetByClass,
  Create,
  Update,
  SaveMovement,
  Delete,
  GetByClassStudent
}

export default ClassRegistrationRepository