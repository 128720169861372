import ClassRegistrationRepository from '@/shared/http/repositories/socialProject/classregistration'
import MessageSmsRepository from '@/shared/http/repositories/socialProject/message-sms'
import TeacherRepository from '@/shared/http/repositories/socialProject/teacher'
import ClassRepository from '@/shared/http/repositories/socialProject/class'
import BatchSmsCriteria from '@/shared/models/criteria/batchsmscriteria'
import BreadCrumb from '@/components/layout/BreadCrumb/breadCrumb.vue'
import SendSms from '@/components/layout/SendSms/sendSms.vue'
import { loading, toast } from '@/shared/utils/UIHelper'
import { IsSuccessCode } from '@/shared/utils/API'

export default {
  components: {
    [BreadCrumb.name]: BreadCrumb,
    [SendSms.name]: SendSms,
  },

  data: () => ({
    criteria: new BatchSmsCriteria(),
    criteriaMessage: { title: null, message: null },
    optionsClass: [],
    optionsTeachers: [],
    messages: [],
    students: [],
    contacts: [],
    totalRegister: 0,
    studentContactIsNull: [],
    showAlertContactNull: false,
    isStudentSms: true,
    specialCharacters: [
      {str: '@RESPNAME@'},
      {str: '@STUDENTCODE@'},
      {str: '@STUDENTBIRTH@'},
      {str: '@STUDENTNAME@'},
      {str: '@STUDENTCPF@'},
      {str: '@STUDENTRG@'},
      {str: '@STUDENTSTATUS@'},
      {str: '@STUDENTREENROLMENT@'},
      {str: '@DATENOW@'},
      {str: '@DATETOMORROW@'},
      {str: '@DATEYESTERDAY@'}
    ],
    paginationMessageSms: {
      count: 1,
      limit: 4,
      index: 1
    }
  }),

  computed: {
    breadCrumb() {
      return {
        breadcrumbItems: [
          {
            text: 'Dashboard',
            href: this.$router.resolve({ name: 'Dashboard' }).href
          },
          {
            text: 'SMS em lotes',
            active: true
          }
        ]
      }
    },
		disable() {
      const hasValues = this.criteria.teacherId != null && this.criteria.classId != null
      
      if (hasValues) {
        return false
      }

      return true
    }
  },

  created() {
		this.getAllTeacher()
		this.getAllMessages()
  },

  methods: {
		getClass() {
      loading.push()
      ClassRepository.GetAll(this.criteria)
        .then(res => {
          if (!IsSuccessCode(res)) return Promise.reject()

          this.optionsClass = res.data.data
          loading.pop()
          return Promise.resolve()
        })
        .catch((err) => {
          loading.pop()
          console.log(err)
          toast.error(err, 'ERRO')
        })
    },

		getAllTeacher() {
      loading.push()
      TeacherRepository.GetAll()
        .then(res => {
          if (!IsSuccessCode(res)) return Promise.reject()

          this.optionsTeachers = res.data.data
          loading.pop()
          return Promise.resolve()
        })
        .catch(() => {
          loading.pop()
          toast.error('Erro ao carregar professores', 'ERRO')
        })
    },

		getAllMessages() {
      this.cardIndex = null
      loading.push()
      MessageSmsRepository.GetAll(this.criteriaMessage, this.paginationMessageSms)
        .then(res => {
          if (!IsSuccessCode(res)) return Promise.reject()

          this.messages = res.data.data

          this.paginationMessageSms = {
            count: res.data.count,
            limit: res.data.pagingLimit,
            index: res.data.pagingIndex
          }
          
          loading.pop()
          return Promise.resolve()
        })
        .catch(() => {
          loading.pop()
          toast.error('Erro ao carregar mensagens', 'ERRO')
        })
    },

    LoadClassRegistration(classeId) {
      loading.push()
      ClassRegistrationRepository.GetByClassStudent(classeId)
        .then(res => {
          if (!IsSuccessCode(res)) return Promise.reject()
          
          this.contacts = res.data.data.map(x => x.contacts)

          const isContactNull = this.contacts.some(x => x == null || x == undefined)

          if (isContactNull) {
            this.showAlertContactNull = true

            this.studentContactIsNull = res.data.data.filter(x => x.contacts == null).map(z => ({
              studentName: z.studentName,
              studentCode: z.studentCode,
              studentCPF: z.studentCPF,
              studentRG: z.studentRG
            }))

            this.students = []
            this.totalRegister = 0
            loading.pop()
            return
          }

          this.studentContactIsNull = []
          this.showAlertContactNull = false

          const respStudent = res.data.data
          const respStudentChanged = this.changeKeyStudentNameToName(respStudent)

          this.students = respStudentChanged
          this.totalRegister = res.data.count

          loading.pop()
          return Promise.resolve()
        })
        .catch(() => {
          loading.pop()
          toast.error('Erro ao carregar os alunos registrados', 'ERRO')
        })
    },

    changeKeyStudentNameToName(arr) {
      if (!arr) return

      Object.keys(arr).forEach(key => {
        const valueKey = arr[key].studentName
        arr[key].name = valueKey
        delete arr[key].studentName
      })

      return arr
    },

    backTo() { this.$router.go(-1) },

    goToNewMessage() { window.location='/cadastros/mensagem-sms/novo' },
      
    clearItem() { this.students = [] }
  }
}
